.home-container {
  flex: 1;
  /* align-items: center; */
  /* justify-content: center; */
  text-align: center;
  padding-top: 10px;
  width: 100%;
  height: 550px;
  background-size: cover;
  /* display: tabel-cell; */
  /* display: inline-block; */
  min-height: 100%; 
  flex-direction: column;
}

.home-card {
  flex-direction: column;
  display: inline-block;
  /* align-items: center;  */
  align-items: center;
  text-align: center;
  justify-content: center;
  background: rgba(128, 128, 128, 0.6);
  margin: 50px;
  padding: 25px;
  
}

.title {
  font-size: 64px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  opacity: 1;
}

.text-container {
  margin: 0 auto;
  display: inline-block;
  /* text-align: center; */
  justify-content: center;
  align-items: center;
  width: 30%;
  background-color: #fff;
  opacity: 0.2;
}