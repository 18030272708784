.footer-container {
  background-color: #330000;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-icon-link {
  color: #fff;
  font-size: 24px;
  /* padding: 20px; */
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
  /* border-style: solid;
  border-color: red; */
}

.icon-container {
  display: flex;
  width: 200px;
  height: 200px;
  /* border-style: solid;
  border-color: red; */
}
.logo-container {
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.logo-label {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.footer-title {
  font-size: 28px;
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 820px) {
  .social-media-wrap {
    flex-direction: column;
  }
}
