.title-container {
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
}

.title {
  font-size: 64px;
  color: #330000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}